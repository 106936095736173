import React from "react"
import { css } from "@emotion/core"
import { colors } from "../../theme"

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const timestamp = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 0 0 32px 0;
`

const Update2 = () => (
  <>
    <h1 css={heroHeadline}>Update</h1>
    <p css={timestamp}>March 2020</p>
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src="https://www.youtube.com/embed/L5KMImQ3J0k?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </>
)

export default Update2
