import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Card from "../components/Card"
import Mar2020 from "../components/updates/Mar2020"
import Sep2020 from "../components/updates/Sep2020"
import Jun2021 from "../components/updates/Jun2021"
import CovidProtocols from "../components/updates/CovidProtocols"
import { smallContainer } from "../components/container"

// Hero Section
const hero = css`
  position: relative;
`
const StyledCard = styled(Card)`
  position: relative;
  z-index: 10;
  margin: 50px auto;
`

const Update = () => (
  <Layout>
    <SEO title="Updates" />
    <Header />
    <div css={[smallContainer, hero]}>
      <StyledCard>
        <CovidProtocols />
      </StyledCard>
      <StyledCard>
        <Jun2021 />
      </StyledCard>
      <StyledCard>
        <Sep2020 />
      </StyledCard>
      <StyledCard>
        <Mar2020 />
      </StyledCard>
    </div>
  </Layout>
)

export default Update
