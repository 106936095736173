import React from "react"
import { css } from "@emotion/core"
import NewlywedsImage from "../../components/NewlywedsImage"
import { colors } from "../../theme"

// Hero Section

const storyText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 20px 0;
`

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const timestamp = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 0 0 32px 0;
`

const Update2 = () => (
  <>
    <h1 css={heroHeadline}>Update, Again</h1>
    <p css={timestamp}>September 2020</p>
    <p css={storyText}>
      Fall is upon us! But unfortunately, our wedding is not!
    </p>
    <p css={storyText}>
      A 2020 celebration just was not meant to be for us. So, under the guidance
      and advice of Avalon Hotel (and the state of California), we have decided
      that 2021 would be the best way for us to responsibly party with you all!
    </p>
    <p css={storyText}>
      We are truly sorry if the changes have complicated anyones planning in the
      process, and are so humbled by the outpouring of love and support you have
      offered us along this unpredictable journey.
    </p>
    <p css={storyText}>And since we are here, a little life update:</p>
    <p css={storyText}>
      In true comedic form, we had engraved our wedding bands with our original
      date (05.16.20) 😬&nbsp; If there is one thing quarantine has taught us,
      it’s that small proclamations have everlasting power, the well-being of
      our loved ones always comes first, and how you approach your goals in
      times of uncertainty can help you see what’s truly important&hellip;
    </p>
    <p css={storyText}>
      Here we are, in May and in love, freshly eloped as Mr. + Mrs. in our
      backyard with 1 legal witness!
    </p>
    <NewlywedsImage />
    <p css={storyText}>
      What has always fueled our excitement for having a wedding, is a day of
      gathering with all of our favorite people, where everyone is in one place,
      everyone is sharing stories, and everyone is family. We still want to have
      this day with you&hellip;it&rsquo;ll just have to be&hellip;next
      year&hellip;(10.11.2021)
    </p>
  </>
)

export default Update2
