import React from "react"
import { css } from "@emotion/core"
import { colors } from "../../theme"

const heroHeadline = css`
  font-size: 36px;
  position: relative;
  top: -25px;
  margin-bottom: 0;
`

const timestamp = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 0 0 32px 0;
`

const storyText = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.roseDark};
  margin: 20px 0;

  a {
    color: ${colors.black};
  }
`

const storyList = css`
  font-size: 18px;
  line-height: 1.3;
  color: ${colors.black};

  > li {
    margin-bottom: 0;
  }
`

const Update2 = () => (
  <>
    <h1 css={heroHeadline}>COVID-19 Protocols</h1>
    <p css={timestamp}>September 2021</p>
    <h3>Avalon Hotel + Bungalows</h3>
    <p css={storyText}>
      Before we begin the party, we have been asked by the venue to share the
      current guidelines in an effort to keep our loved ones safe and feeling
      comfortable at the wedding. Avalon will require the following from all
      adult guests over 12:
    </p>
    <ul css={storyList}>
      <li>
        <strong>Proof of vaccination</strong> (we suggest downloading your copy
        to your phone)
      </li>
      <i>or</i>
      <li>
        <strong>Negative COVID test</strong> within 72 hours of the event
      </li>
    </ul>
    <p css={storyText}>
      Face coverings will not be required at the event. The wedding and
      reception will all be outdoors, with a ballroom for music and dancing at
      the end of the night (totally optional, you can hang out outside as well!)
    </p>
    <h3>Palm Springs</h3>
    <p css={storyText}>
      The city has enacted a strict policy of COVID guidelines in order to help
      stop the spread of the virus and keep its visitors and locals safe. The
      gist of it is, as of right now, you will need the same as above for all
      indoor resturaunts and bars. It also sounds like face coverings are
      required indoors regardless of vaccination status. For the most up to date
      information, visit the{" "}
      <a
        href="https://www.palmspringsca.gov/government/covid-19-updates
"
        target="_blank"
      >
        official Palm Springs website
      </a>
      .
    </p>
    <p css={storyText}>
      We also recommend making reservations ahead of time! Palm Springs is busy
      this time of year. Visit our <a href="/what-to-do">What to Do</a> page to
      help navigate a variety of restaurants for the best experience for you and
      your loved ones!
    </p>
  </>
)

export default Update2
